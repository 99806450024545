import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import { noAuthRoutes } from '../constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'MultipleSitesDashboard',
    component: () => import('../views/MultipleSitesDashboard.vue')
  },
  {
    path: '/single-site/:id',
    name: 'SingleSiteDashboard',
    component: () => import('../views/single-site-dashboard/SingleSiteDashboard.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/UserManagement/UserManagementView.vue'),
    children: [
      {
        path: 'user-group-management',
        name: 'UserGroupManagement',
        component: () => import('../views/RoleManage/RolesManageView.vue')
      },
      {
        path: 'user-management',
        name: 'UserManagement',
        component: () => import('../views/UserManager/UserManageView.vue')
      },
    ]
  },
  {
    path: '/tariffs',
    name: 'Tariffs',
    component: () => import('../views/tariffs/TariffTabs.vue'),
    children: [
      {
        path: 'holiday-management',
        name: 'HolidayManagement',
        component: () => import('../views/tariffs/HolidayManagement.vue')
      },
      {
        path: 'time-of-use-management',
        name: 'TimeOfUseManagement',
        component: () => import('../views/tariffs/TimeOfUseManagement.vue')
      },
      {
        path: 'tariff-management',
        name: 'TariffManagement',
        component: () => import('../views/tariffs/TariffManagement.vue')
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  }
];

const routesAllowUserNames = [
  {
    path: '/data-analytic/:id',
    name: 'DataAnalyticDashboard',
    component: () => import('../views/data-analytic-dashboard/DataAnalyticDashboard.vue')
  },
  {
    path: '/data-saving/:id',
    name: 'DataSavingDashboard',
    component: () => import('../views/data-saving-dashboard/DataSavingDashboard.vue')
  },
  {
    path: '/asset-management/:id',
    name: 'AssetManagement',
    component: () => import('../views/asset-management/AssetManagement.vue')
  },
  {
    path: '/alarm-management/:id',
    name: 'AlarmManagement',
    component: () => import('../views/alarm-management/AlarmManagement.vue')
  },
  {
    path: '/inventory-management',
    name: 'InventoryManagement',
    component: () => import('../views/inventory-management/InventoryManagement.vue')
  },
];

if(["Toai01", "matthew", "victor", "minh", "trieu", "ducnguyen", "binhphung", "kokwai01", "matthew01", "superadmin02", "superadmin2", "taolakhoa"].includes(store.state.user?.username)){
  routes.push(...routesAllowUserNames);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (noAuthRoutes.findIndex(el => el == to.name) < 0 && !store.getters.isLoggedIn) {
    if (!sessionStorage.getItem('token')) { 
      next({ name: 'Login' })
    } else {
      next();
    }
  } else {
    if(from.path == '/login' && to.path == '/' && (!store.getters.accessibleRoutes?.multi_site?.view || store.getters.user?.user_site?.length === 1)) {
      let getSiteId = store.getters.user?.user_site?.length ? store.getters.user.user_site[0].site_id : null;
      if(getSiteId && store.getters.accessibleRoutes.single_site.view) {
        store.commit('setSiteId', getSiteId);
        localStorage.setItem('selectedSiteId', store.getters.siteId);
        router.push({ path: '/single-site/' + getSiteId });
        if (!store.getters.isHeaderMenuFul) {
          store.commit('setHeaderMenu', true);
        }
      } else {
        router.push({ path: '*' });
      }
    } else {
      if (['/', 
        '/inventory-management', 
        '/users/user-management', 
        '/users/user-group-management', 
        '/tariffs/tariff-management', 
        '/tariffs/time-of-use-management', 
        '/tariffs/holiday-management'
      ].includes(to.path)) {
        if (store.getters.isHeaderMenuFul) {
          store.commit('setHeaderMenu', false);
        }
        // Check permissions to view screens
        if (['UserManagement', 'UserGroupManagement'].includes(to.name) && (!store.getters.accessibleRoutes?.user?.view || ["site_engineer", "home_user"].includes(store.getters.user?.user_group?.type))) {
          next({ name: 'Login' })
        } 
        if (['InventoryManagement'].includes(to.name) && !store.getters.accessibleRoutes?.inventory?.view) {
          next({ name: 'Login' })
        }
      } else {
        if (!store.getters.isHeaderMenuFul) {
          store.commit('setHeaderMenu', true);
        }
        // Check permissions to view screens
        if (['SingleSiteDashboard'].includes(to.name) && !store.getters.accessibleRoutes?.single_site?.view) {
          next({ name: 'Login' })
        } 
        if (['DataAnalyticDashboard', 'DataSavingDashboard'].includes(to.name) && !store.getters.accessibleRoutes?.data_analytic?.view) {
          next({ name: 'Login' })
        } 
        if (['AssetManagement'].includes(to.name) && !store.getters.accessibleRoutes?.asset?.view) {
          next({ name: 'Login' })
        }
        if (['AlarmManagement'].includes(to.name) && !store.getters.accessibleRoutes?.alarm?.view) {
          next({ name: 'Login' })
        }
      }
      next();
    }
  }
})

export default router
